import React from "react";
import "./About.css";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";

export default function About() {
  return (
    <div className="about__page__container">
      <Header />
      <div className="about__page__all__text width-lg">
        <div className="about__page__one">
          <h4>رسالت بوتواستارت</h4>
          <p>
            قبل از هر چیزی، یک دوره آموزشی باید با زبانی کاملا ساده و شیوا گفته
            شده باشد و به تمام و جزییات و نکاتی که ممکن است برای مخاطب سوال به
            وجود آورد بپردازد. این نکته در عین سادگی‌بسیار مهم است چرا که قدم
            های بعدی آموزش و یادگیری شما به درک مفاهیم پایه بستگی دارد و سعی
            کردیم که این کار را به بهترین نحو انجام دهیم.
          </p>
        </div>
        <div className="about__page__two">
          <h4>ویژگی های آموزش ما</h4>
          <div>
            <span>زبان ساده و روان</span>
            <p>
              امروزه انتخاب یک دوره آموزشی کار بسیار سختی است، چرا که بی نهایت
              دوره در اینترنت پیدا میشود که حتی به کوچکترین استاندارد های یک
              دوره آموزشی مناسب دقت نکرده اند. ما در بوتواستارت سعی میکنیم با
              رعایت این استاندارد ها و ارائه دوره‌ هایی با کیفیت و کامل مسیر
              پیشرفت شمارو هموار بکنیم، این دوره ها حاصل سال ها تجربه کار برنامه
              نویسی و آموزش آن است که به صورت یکجا درآمده تا در دسترس شما قرار
              گیرد و بتوانید بهترین نتیجه را از آن بگیرید.
            </p>
            <span>از صفر تا پیشرفته</span>
            <p>
              در قدم بعدی سعی کردیم مباحث را از صفر (به صورتی که نیاز به هیچ
              پیشنیازی نداشته باشید) تا پیشرفته پوشش دهیم تا فقط با شرکت در یک
              دوره به سطحی مناسب برای ورود به بازار کار برسید.
            </p>
            <span>پروژه محور</span>
            <p>
              بعد از یادگیری مفاهیم، چیزی که باعث درک و تسلط شما میشود انجام
              پروژه و تمرین است که در تمام دوره های بوتواستارت تعداد زیادی تمرین
              و پروژه های پیشرفته حل شده تا دید بسیار دقیقی از برنامه به شما
              داده شود.
            </p>
            <span>پشتیبانی دائمی</span>
            <p>
              هر کسی در ابتدای راه برنامه نویسی با یک سری سوالات و ابهامات رو به
              رو میشود، چه فنی و چه غیر فنی. این سوالات ممکن است روز ها و هفته
              ها از وقت شما را تلف کند اما با وجود یک برنامه نویس حرفه‌ای و
              پشتیبان در کنار شما خیلی زود این ابهامات کنار زده خواهد شد و
              پیشرفت سریع تری خواهید داشت، به همین دلیل دوره های‌ بوتواستارت
              پشتیبانی دائمی دارند تا در تمام مراحل یادگیری، استخدام و بعد از
              استخدام کنار شما باشیم.
            </p>
            <span>آپدیت</span>
            <p>
              برنامه نویسی علمی‌در حال پیشرفت است و ما سعی کرده این تا دوره ها
              را آپدیت نگه داریم به صورتی که آخرین ورژن هر تکنولوژی در داخل دوره
              ها پوشش داده شده است تا همیشه در لبه تکنولوژی بمانید.
            </p>
            <span>وبینار های هفتگی</span>
            <p>
              مسیر یادگیری پستی و بلندی های زیادی دارد، در این مسیر با هم هستیم
              و با برگزاری وبینار های هفتگی دور هم جمع میشویم و از مشکلات و چالش
              ها حرف میزنیم و برایشان راه حل پیدا میکنیم. تجربه ثابت کرده که
              وبینار های هفتگی تاثیر بسیار زیادی بر روی انگیزه و انرژی دانشجو ها
              برای ادامه یادگیری میگذارد. و کلی برنامه مثبت دیگر که در آینده
              اجرا خواهد شد و مختص بوتواستارت است.
            </p>
          </div>
        </div>
        <div className="about__page__three">
          <h4>دوره ها تضمین استخدام دارند؟</h4>
          <p>
            رسیدن به مرحله استخدام دو فاکتور بسیار مهم دارد: ۱-دوره آموزشی،
            ۲-تلاش شما در دوره های بوتواستارت هر چیزی که برای رسیدن به مرحله
            استخدام نیاز است در اختیار شما گذاشته شده است اما این شما هستید که
            باید از این محتوا استفاده کنید و تلاش شما است که باعث به نتیجه رسیدن
            دوره ها میشود. پس اگر شما قول بدهید که دوره هارا کامل ببینید و تمرین
            کنید ما هم این قول را میدهیم که به زودی به مرحله استخدام خواهید
            رسید.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
